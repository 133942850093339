<template>
  <v-container>
    <v-row>
      <v-col class="implementation-title">
        <h5 class="subtitle-1">Realizar upload das seguintes categorias</h5>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              text
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="downloadFileBiobe"
            >
              Baixar layout biobe
              <v-icon right color="primary"> fa-solid fa-download </v-icon>
            </v-btn>
          </template>
          <span>Baixar layout de implantação Biobe</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        xl="3"
        lg="3"
        md="3"
        sm="3"
        v-for="(card, index) in item.cards"
        :key="index"
      >
        <CardUpload
          :amountRegistered="card.amountRegistered"
          :amountContract="card.amountContract"
          :productType="card.productType"
          :hasTemplate="card.hasTemplate"
          :implementationId="implementationId"
          :cannotEdit="cannotEdit"
          @file="uploadDocument"
        />
      </v-col>
    </v-row>

    <BaseTableList title="Histórico de arquivos do cliente">
      <template v-slot:table>
        <v-data-table
          item-key="id"
          dense
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="item.files"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="item.situation !== 'Processado com erros'"
                  v-on="on"
                  color="success"
                  small
                  @click="downloadDocument(item)"
                >
                  fa-solid fa-download
                </v-icon>

                <v-icon
                  v-else
                  v-on="on"
                  color="error"
                  small
                  @click="handleDownloadHistoryOrErrorFile(item)"
                >
                  fa-solid fa-download
                </v-icon>
              </template>
              <span>Baixar arquivo</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-row v-if="cannotEdit">
      <v-col cols="12">
        <PageReview :implementationId="implementationId" :step="1" />
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
        <template v-if="hasProcess">
          <BaseButton @click="finalize" color="primary" title="Processar" />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { ImplementationService } from '@/services/api/implementation';

export default {
  mixins: [rulesMixin],

  components: {
    CardUpload: () => import('@/components/cards/card-upload'),
    PageReview: () => import('@/components/pages/implementation/page-review')
  },

  data: () => ({
    valid: true,
    headers: [
      { text: 'Tipo', value: 'productType' },
      { text: 'Data de envio', value: 'createdAt' },
      { text: 'Arquivo', value: 'fileName' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action' }
    ],
    item: {
      cards: [],
      files: []
    }
  }),

  props: {
    implementationId: {
      type: String
    }
  },

  created() {
    this.search();
  },

  computed: {
    cannotEdit() {
      const { situation } = this.$route.params;

      return situation === 'Concluído' ? false : true;
    },

    hasProcess() {
      return (
        this.item.files.filter(x => x.situation === 'Em processamento').length >
        0
      );
    }
  },

  methods: {
    formatDate,

    back() {
      this.$emit('back');
    },

    async search() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.getImplantedLives({
          implementationId: this.implementationId
        });

        if (status === 200) {
          this.item = data;
        } else {
          this.item = {
            cards: [],
            files: []
          };
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadFileBiobe() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.downloadLayoutBiobe();

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadDocument(item) {
      try {
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.downloadLivesFile({
          implementationId: this.implementationId,
          fileName: item.fileName
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async handleDownloadHistoryOrErrorFile(item) {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.downloadHistoryOrErrorFile({
            implementationId: this.implementationId,
            fileName: item.fileName,
            errorFile: true
          });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async finalize() {
      try {
        const implementationService = new ImplementationService();
        const { status } = await implementationService.finalizeImplementation(
          this.implementationId
        );

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async uploadDocument(params) {
      try {
        const implementationService = new ImplementationService();
        const { status } =
          await implementationService.uploadDocumentImplantedLives(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>

<style lang="scss">
.implementation-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .implementation-title {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    button {
      padding: 0 !important;
    }
  }
}
</style>
